/* eslint-disable */
import { auth } from '@/plugins/auth/firebase.js'
import Cookie from 'js-cookie'
import JWTDecode from 'jwt-decode'
import axios from 'axios'
import * as vars from '@/vars.json';
export const subscriptions = {
    namespaced: true,
    state: {
        payments_list: [],
        stripe: null,
        plans_list: null,
        currentPlan: null,
        isLoading: false,
        main_card: null,
    },

    mutations: {

        SET_PAYMENTS_LIST (state, payload) {
            state.payments_list = payload
        },
        SET_STRIPE (state, payload) {
            state.stripe = payload
        },
        SET_PLANS_LIST (state, payload) {
            state.plans_list = payload
        },
        SET_LOADING (state, payload) {
            state.isLoading = payload
        },
        SET_CURRENT_PLAN (state, payload) {
            state.currentPlan = payload
        },
        SET_MAIN_CARD (state, payload) {
            state.main_card = payload
        },
    },

    actions: {
        async loadPayments ({ commit, state, rootState }, type) {
             return new Promise((resolve, reject) => {
                // console.log('aquiiiiiiiiiiiii')
                let userId = '' 
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                // console.log(userId)
                let path = `users/${userId}/payments`
                let loadCommit = 'SET_PAYMENTS_LIST'

                // console.log(path)
                 firebase.database().ref(path).orderByChild('name').on('value',
                    function (snapshot) {
                        let items =  null
                        if (snapshot.val()){
                            items = Object.values(snapshot.val())
                        }
                        commit(loadCommit, items)
                        commit('SET_LOADING', false)
                        resolve()
                    }, function (error) {
                        console.error(error)
                        commit('SET_LOADING', false)
                        reject()
                    },
                )
            })
        },
        async loadStripe ({ commit, state, rootState, dispatch }, type) {
            return new Promise((resolve, reject) => {
               let userId = rootState.users.user.uid
               // console.log(userId)
               let path = `users/${userId}/stripe`
               let loadCommit = 'SET_STRIPE'

               // console.log(path)
                firebase.database().ref(path).orderByChild('name').on('value',
                   function (snapshot) {
                        if (snapshot.val()) {
                            let cards 
                            cards = Object.values(snapshot.val().cards)
                            cards.forEach(element => {
                                if (element.main) {
                                    commit('SET_MAIN_CARD', element)
                                }
                            });
                        }
                        // if (snapshot.val()) dispatch('compareStripe', snapshot.val())
                        commit(loadCommit, snapshot.val())
                        resolve()
                   }, function (error) {
                       console.error(error)
                       commit('SET_LOADING', false)
                       reject()
                   },
               )
           })
        },
        async compareStripe ({ commit, state, rootState, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const uid = rootState.users.user.uid
                const tokenCustomer = {
                    token: payload.customer
                }
                axios.post(vars.urlProd+'/getCustomer', tokenCustomer).then(res => {
                    // Variables a comparar
                    const stripe_status = res.data.subscription.subscriptions.data[0].status,
                    stripe_start = res.data.subscription.subscriptions.data[0].current_period_start,
                    stripe_end = res.data.subscription.subscriptions.data[0].current_period_end
                    // Comparacion de valores
                    if (payload.subscription && payload.subscription.status !== stripe_status || payload.subscription.start !== stripe_start || payload.subscription.end !== stripe_end) {
                        const updateObject = {
                            status: stripe_status,
                            start: stripe_start,
                            end: stripe_end
                        }
                        // console.log('actualizaaaa')
                        firebase.database().ref(`users/${uid}/stripe/subscription`).update(updateObject)
                    }
                    resolve()
                }).catch(e => {
                    // console.log(e)
                    reject()
                })
            })
        },
        async loadPlans ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                // console.log('planssssss')
                let loadCommit = 'SET_PLANS_LIST'
                axios.post(vars.urlProd+'/getPlans').then(res => {
                    // console.log(res)
                    let items = res.data.plans.data.filter(plan => plan.active);
                    items.sort(function(a, b) { return a.amount - b.amount;})
                    // console.log(items)
                    commit(loadCommit, items)
                    resolve()
                }).catch(e => {
                    // console.log(e)
                    reject()
                })
            })
        },
        setPlan ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                let loadCommit = 'SET_CURRENT_PLAN'
                if (payload) {
                    commit(loadCommit, payload)
                    resolve()
                } else {
                    commit(loadCommit, {})
                    reject()
                }
            })
        },
        async createcard ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                const userId = rootState.users.user.uid
                const key = firebase.database().ref().child(`users/${userId}/stripe/cards`).push().key
                const card = {
                    id: key,
                    token: payload.paymentMethod.id,
                    brand: payload.paymentMethod.card.brand,
                    number: payload.paymentMethod.card.last4,
                    exp_month: payload.paymentMethod.card.exp_month,
                    exp_year: payload.paymentMethod.card.exp_year,
                    type: payload.paymentMethod.card.funding,
                    main: false,
                }
                // console.log(card)
                let updates = {}
                updates[`users/${userId}/stripe/cards/${key}`] = card
                firebase.database().ref().update(updates).then(function () {
                    resolve(key)
                }).catch(function (err) {
                    reject(err)
                })
            })
        },
    },
    getters: {
        getPayments (state) {
            return state.payments_list
        },
        getStripe (state) {
            return state.stripe
        },
        getPlans (state) {
            return state.plans_list
        },
        getCurrentPlan (state) {
            return state.currentPlan
        },
        getMainCard (state) {
            return state.main_card
        },
    },

}
