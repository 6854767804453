/* eslint-disable */

/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

import { drawer } from './modules/drawer'
import { users } from './modules/users'
import { dialogs } from './modules/dialogs'
import { summary } from './modules/summary'
import { subscriptions } from './modules/subscriptions'
import { loading } from './modules/loading'
import { collaborators } from './modules/collaborators'
import { idle } from './modules/idle'
import { uploads } from './modules/uploads'
import { downloads } from './modules/downloads'
import { withdraw } from './modules/withdraw'
import { search } from './modules/search'
import { notifications } from './modules/notifications'
import { admin } from './modules/admin'
import * as firebase from 'firebase'
import { fireDb, auth, GoogleProvider } from '../plugins/auth/firebase.js'

global.firebase = firebase


Vue.use(Vuex)

// Create a new store
export default new Vuex.Store({
  modules: {
    drawer,
    users,
    dialogs,
    summary,
    subscriptions,
    loading,
    collaborators,
    idle,
    uploads,
    downloads,
    withdraw,
    search,
    notifications,
    admin
  },
})
