/* eslint-disable */

export const notifications = {
    namespaced: true,
    state: {
        notification_list: [],
        isLoading: false,
        noread_list: [],
    },

    mutations: {

        SET_NOTIFICATIONS_LIST (state, payload) {
            state.notification_list = payload
        },
        SET_NOREAD_LIST (state, payload) {
            state.noread_list = payload
        },
        SET_LOADING (state, payload) {
            state.isLoading = payload
        },
    },

    actions: {
        async loadNotifications ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let path = `users/${userId}/notifications`
                let loadCommit ='SET_NOTIFICATIONS_LIST'
                firebase.database().ref(path).orderByChild('name').limitToLast(10).on('value',
                function (snapshot) {
                    let items = Object.values(snapshot.val()) || null;
                    items.sort(function(a,b){
                        return b.created - a.created
                    });
                    commit(loadCommit, items)
                    resolve()
                }, function (error) {
                    console.error(error)
                    reject()
                },
            )
            })
        },
        async loadNoRead ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let path = `users/${userId}/notifications`
                let loadCommit ='SET_NOREAD_LIST'
                firebase.database().ref(path).orderByChild('read').equalTo(false).limitToLast(10).on('value',
                function (snapshot) {
                    let items = []
                    if (snapshot.val()) {
                        snapshot.forEach(function (child) {
                            items.push({
                                ...child.val(),
                                id: child.key
                            });
                        });
                    }
                    commit(loadCommit, items.sort())
                    resolve()
                }, function (error) {
                    console.error(error)
                    reject()
                },
            )
            })
        },
        async UpdateNoRead ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                state.noread_list.forEach(function (notification) {
                    let read = {
                        read: true
                    }
                    firebase.database().ref(`users/${userId}/notifications/${notification.id}`).update(read)
                })
            })
        },
    },
    getters: {
        getNotifications (state) {
            return state.notification_list
        },
        getNoRead (state) {
            return state.noread_list
        },
        getLoading (state) {
            return state.isLoading
        },
    },

}
