/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import './plugins/base'
import './plugins/konva'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;
// firebase
import './plugins/auth/firebase.js'
import './plugins/auth/fireauth.js'

import firebaseui from 'firebaseui';

// idle
import IdleVue from "idle-vue";

// Google maps
import * as VueGoogleMaps from "vue2-google-maps";

//Icons
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

/* MOMENT TO TAKE SNAPSHOTS OF THE TIME CREATED, ALSO HELPS TO SHOW THE TIME WHEN THE PUBLICATION WAS CREATED IN, Hours, days, months.. etc */
import moment from 'moment'

// Sharing social
import VueSocialSharing from 'vue-social-sharing'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import 'chartist/dist/chartist.min.css'
import * as ChartistTooltips from 'chartist-plugin-tooltips'
//- Camera
import { defineCustomElements } from '@ionic/pwa-elements/loader'

// Vue.use(TeamHiveVideoRecorder)

require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment,
})

Vue.use(require('vue-chartist'), {
  messageNoData: "You have not enough data",
  classNoData: "empty"
})

Vue.config.productionTip = false

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 10 minutes,
  startAtIdle: false,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBRPQ6JpvWq9yx0sEqR_KRkINYWdC3hu8M",
    libraries: "places",
    region: 'us',
    language: 'en',
  }
});

Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

defineCustomElements(window)