/* eslint-disable */
export const search = {
    namespaced: true,
    state: {
        currentSearch: {}
    },

    mutations: {
        SET_CURRENT_SEARCH (state, payload) {
            state.currentSearch = payload
        },
    },

    actions: {
        setCurrentSearch ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                let loadCommit = 'SET_CURRENT_SEARCH'
                if (payload) {
                    commit(loadCommit, payload)
                    resolve()
                } else {
                    commit(loadCommit, {})
                    reject()
                }
            })
        },
    },
    getters: {
        getCurrentSearch (state) {
            return state.currentSearch
        },
    },

}
