/* eslint-disable */
import { auth } from '@/plugins/auth/firebase'
// import Cookie from 'js-cookie'
// import JWTDecode from 'jwt-decode'

export const withdraw = {
    namespaced: true,
    state: {
        withdraw_list: [],
        banking_info: [],
        earning: null,
    },

    mutations: {

        SET_WITHDRAW_LIST (state, payload) {
            state.withdraw_list = payload
        },
        SET_BANKING_INFO (state, payload) {
            state.banking_info = payload
        },
        SET_EARNINGS (state, payload) {
            state.earning = payload
        }
    },

    actions: {
        async loadWithdraw ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                // console.log('cargando withdraaa')
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let path = `users/${userId}/withdraw`
                let loadCommit ='SET_WITHDRAW_LIST'
                firebase.database().ref(path).orderByChild('name').on('value',
                function (snapshot) {
                    let items =  null
                    if (snapshot.val()){
                        items = Object.values(snapshot.val())
                    }
                    commit(loadCommit, items)
                    resolve()
                }, function (error) {
                    reject()
                },
            )
        })
        },
        async loadBanking ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let path = `users/${userId}/banking`
                let loadCommit = 'SET_BANKING_INFO'
                // Load banking info
                firebase.database().ref(path).orderByChild('name').on('value',
                   function (snapshot) {
                       let item = snapshot.val() || {}
                       commit(loadCommit, item)
                   }, function (error) {
                       console.error(error)
                   },
               )
               // Load earnings
               firebase.database().ref(`users/${userId}/earnings`).orderByChild('name').on('value',
                   function (snapshot) {
                       let item = snapshot.val() || 0
                       commit('SET_EARNINGS', item)
                       resolve()
                   }, function (error) {
                       console.error(error)
                       reject()
                   },
               )
            })
        },
        async createWithdraw ({ commit, state, rootState }, payload){
            return new Promise((resolve, reject) => {
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                // withdraw admin
                // console.log(payload)
                const key = firebase.database().ref().child(`users/${userId}/withdraw`).push().key
                const withdrawUser = {
                    created: firebase.database.ServerValue.TIMESTAMP,
                    status: 'pending',
                    amount_charity: payload.charity,
                    charity: payload.charityName || 'None', 
                    amount: payload.withdraw,
                    type: payload.type
                }
                let updates = {}
                updates[`users/${userId}/withdraw/${key}`] = withdrawUser
                firebase.database().ref().update(updates).then(function () {
                    // Withdraw admin
                    let withdrawAdmin = {
                        created: firebase.database.ServerValue.TIMESTAMP,
                        status: 'pending',
                        amount_charity: payload.charity,
                        amount: payload.withdraw,
                        charity: payload.charityName || 'None',
                        userId: userId,
                        banking: {
                            first_name: state.banking_info.first_name || 'No First name',
                            last_name: state.banking_info.last_name || 'No Last name',
                            email: state.banking_info.email || rootState.users.user.email,
                            account: state.banking_info.account || 'No account'
                        },
                        withdrawId: key,
                        type: payload.type
                    }
                    firebase.database().ref(`admin/withdraw/`).push(withdrawAdmin).then(function () {
                        resolve()
                    }).catch(function (err) {
                        reject(err)
                    })
                })
            })
        },
        async updateBanking ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                const vm = this
                // console.log(payload)
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                firebase.database().ref(`users/${userId}/banking`).update(payload).then(function (res) {
                    resolve(res)
                }).catch(function (err) {
                    reject(err)
                })
            })
        },
    },
    getters: {
        getWithdraw (state) {
            return state.withdraw_list
        },
        getBanking (state) {
            return state.banking_info
        },
        getEarnings (state) {
            return state.earning
        },
    },

}
