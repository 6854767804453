/* eslint-disable */
import { auth } from '@/plugins/auth/firebase.js'
import Cookie from 'js-cookie'
import Vue from 'vue'
import { _ } from 'vue-underscore'

export const summary = {
    namespaced: true,
    state: {
        criteria: '',
        transactions: null,
        purchasesDayList: { results: [] },
        purchasesWeekList: { results: [] },
        purchasesMonthList: { results: [] },
        purchasesYearList: { results: [] },
        salesDayList: null,
        salesWeekList: null,
        salesMonthList:null,
        salesYearList: null,
        currentItem: { results: [] },

        allDaySales: [],
        currentType: 'sales',

        isLoading: false,
        currentShop: {},

    },
    mutations: {

        SET_ALL_DAY_SALES (state, payload) {
            state.allDaySales = payload
        },
        SET_TODAY_PURCHASES_LIST (state, payload) {
            state.purchasesDayList = payload
        },
        SET_WEEK_PURCHASES_LIST (state, payload) {
            state.purchasesWeekList = payload
        },
        SET_MONTH_PURCHASES_LIST (state, payload) {
            state.purchasesMonthList = payload
        },
        SET_YEAR_PURCHASES_LIST (state, payload) {
            state.purchasesYearList = payload
        },
        SET_TODAY_SALES_LIST (state, payload) {
            state.salesDayList = payload
        },
        SET_WEEK_SALES_LIST (state, payload) {
            state.salesWeekList = payload
        },
        SET_MONTH_SALES_LIST (state, payload) {
            state.salesMonthList = payload
        },
        SET_YEAR_SALES_LIST (state, payload) {
            state.salesYearList = payload
        },
        SET_CURRENT_SHOP (state, payload) {
            state.currentShop = payload
        },

        SET_LOADING (state, payload) {
            state.isLoading = payload
        },
    },

    actions: {
        setChart({commit}, payload){
          const preset = payload[0]
          const type = payload[1]
          const data = payload[2]
          // console.log(preset, type, data)
          if(type ==='sales'){
            switch(preset){
              case 'today':
                commit('SET_TODAY_SALES_LIST',data)
                break;
              case 'week':
                commit('SET_WEEK_SALES_LIST',data)
                break;
              case 'month':
                commit('SET_MONTH_SALES_LIST',data)
                break;
              case 'year':
                commit('SET_YEAR_SALES_LIST',data)
                break;
            }
          }
        },
        clearCharts ({ commit }) {
            // commit('SET_TODAY_PURCHASES_LIST',{});
            // commit('SET_WEEK_PURCHASES_LIST',{});
            // commit('SET_MONTH_PURCHASES_LIST',{});
            // commit('SET_YEAR_PURCHASES_LIST',{});
            // commit('SET_TODAY_SALES_LIST',{});
            // commit('SET_WEEK_SALES_LIST',{});
            // commit('SET_MONTH_SALES_LIST',{});
            // commit('SET_YEAR_SALES_LIST',{});

        },
        loadTodayCharts ({ dispatch, commit, state, rootState }) {
            let dateFrom
            let dateTo
            let loadCommit
            const currentShop = state.currentShop
            const type = 'sales'
            let userId = '' 
            if(rootState.users.user.adminUid){
                userId = rootState.users.user.adminUid
            }else{
                userId = rootState.users.user.uid
            }
            const path = `users/${userId}/transactions/${type}`
            const timeRange = 'today'
            switch (timeRange) {
                case 'today':
                    dateFrom = Vue.moment().subtract(23, 'hours')
                    dateTo = Vue.moment().add(1, 'hours')
                    loadCommit = 'SET_ALL_DAY_SALES'

                    break
            }
            return new Promise((resolve, reject) => {
                const fireDB = firebase.database().ref().child(path)
                let fireRef
                fireRef = fireDB.orderByChild('time').startAt(dateFrom).endAt(dateTo)
                fireRef.on('value',
                    function (data) {
                        const items = []
                        const itemList = data.val() || {}
                        Object.keys(itemList).forEach(function (key) {
                            if (currentShop.hasOwnProperty('id')) {
                                if (type === 'purchases') {
                                    if (currentShop.id === itemList[key].location_to.id) {
                                        items.push({
                                            ...itemList[key],
                                        })
                                    }
                                } else {
                                    if (currentShop.id === itemList[key].location_from.id) {
                                        items.push({
                                            ...itemList[key],
                                        })
                                    }
                                }
                            } else {
                                items.push({
                                    ...itemList[key],
                                })
                            }
                        })

                        // console.log('TODAY LIST : ', items);

                        commit(loadCommit, items)

                        commit('SET_LOADING', false)
                        resolve()
                    }, function (error) {
                        commit('SET_LOADING', false)
                        console.error(error)
                        reject()
                    },
                )
            })
        },
        // loadCharts({dispatch, commit, rootState},location) {
        //     try{
        //     dispatch('clearCharts');
        //     // dispatch('loadChartByRange', {preset:'today', type:'sales'});
        //     // dispatch('loadChartByRange', {preset:'week', type:'sales'});
        //     // dispatch('loadChartByRange', {preset:'month', type:'sales'});
        //     // dispatch('loadChartByRange', {preset:'today', type:'purchases'});
        //     // dispatch('loadChartByRange', {preset:'week', type:'purchases'});
        //     // dispatch('loadChartByRange', {preset:'month', type:'purchases'});
        //     }
        //     catch(err){
        //         console.log(err);
        //         if(!rootState.users.user){
        //                 console.log('el usuario no esta establecido');
        //                  auth.signOut();
        //                  Cookie.remove('access_token');

        //                 dispatch('users/clearUser').then(function(){
        //                     // user info deleted
        //                     console.log('sesion borrada')

        //                 })
        //             }else{
        //             console.log('user : ', rootState.users.user)
        //         }

        //     }

        // },
        loadChartByRange ({ state, commit, rootState }, payload) {
            commit('SET_LOADING', true)

            let dateFrom
            let dateTo
            let loadCommit
            const currentShop = state.currentShop
            const type = payload.type
            let userId = '' 
            if(rootState.users.user.adminUid){
                userId = rootState.users.user.adminUid
            }else{
                userId = rootState.users.user.uid
            }
            const path = `users/${userId}/transactions/${type}`
            const timeRange = payload.preset
            switch (timeRange) {
                case 'today':
                    dateFrom = Vue.moment().subtract(23, 'hours').format()
                    dateTo = Vue.moment().add(1, 'hours').format()
                    loadCommit = type === 'sales' ? 'SET_TODAY_SALES_LIST' : type === 'purchases' ? 'SET_TODAY_PURCHASES_LIST' : ''
                    // console.log('today',dateFrom, dateTo);
                    break
                case 'week':
                    // dateFrom = Vue.moment().startOf('isoWeek').format('YYYY-MM-DD');
                    dateFrom = Vue.moment().subtract(6, 'days').format('YYYY-MM-DD')
                    dateTo = Vue.moment().add(1, 'days').format('YYYY-MM-DD')
                    // loadCommit = 'SET_WEEK_SALES_LIST';
                    loadCommit = type === 'sales' ? 'SET_WEEK_SALES_LIST' : type === 'purchases' ? 'SET_WEEK_PURCHASES_LIST' : ''
                    // console.log('week',dateFrom, dateTo);
                    break
                case 'month':

                    dateFrom = Vue.moment().subtract(29, 'days').format('YYYY-MM-DD')
                    dateTo = Vue.moment().add(1, 'days').format('YYYY-MM-DD')

                    // loadCommit = 'SET_YEAR_SALES_LIST';
                    loadCommit = type === 'sales' ? 'SET_MONTH_SALES_LIST' : type === 'purchases' ? 'SET_MONTH_PURCHASES_LIST' : ''
                    // console.log('month',dateFrom, dateTo);
                    break
                case 'year':
                    if (type === 'sales') {
                        dateFrom = Vue.moment().subtract(1, 'years').format('YYYY-MM-DD')
                        dateTo = Vue.moment().add(1, 'month').format('YYYY-MM-DD')
                    } else {
                        dateFrom = Vue.moment().subtract(1, 'month').format('YYYY-MM-DD')
                        dateTo = Vue.moment().add(1, 'day').format('YYYY-MM-DD')
                    }

                    // loadCommit = 'SET_YEAR_SALES_LIST';
                    loadCommit = type === 'sales' ? 'SET_YEAR_SALES_LIST' : type === 'purchases' ? 'SET_YEAR_PURCHASES_LIST' : ''
                    // console.log('year',dateFrom, dateTo);
                    break
            }
            return new Promise((resolve, reject) => {
                const fireDB = firebase.database().ref().child(path)
                let fireRef
                fireRef = fireDB.orderByChild('time').startAt(dateFrom).endAt(dateTo)
                fireRef.on('value',
                    function (data) {
                        const items = []
                        const itemList = data.val() || {}
                        Object.keys(itemList).forEach(function (key) {
                            if (currentShop.hasOwnProperty('id')) {
                                if (type === 'purchases') {
                                    if (currentShop.id === itemList[key].location_to.id) {
                                        items.push({
                                            ...itemList[key],
                                        })
                                    }
                                } else {
                                    if (currentShop.id === itemList[key].location_from.id) {
                                        items.push({
                                            ...itemList[key],
                                        })
                                    }
                                }
                            } else {
                                items.push({
                                    ...itemList[key],
                                })
                            }
                        })

                        // console.log(items)
                        const grouped = _.groupBy(items, function (el) {
                            if (timeRange === 'today') {
                                return Vue.moment(el.time).format('ha')
                            }
                            if (timeRange === 'week') {
                                return Vue.moment(el.time).format('dd')
                            }
                            if (timeRange === 'month') {
                                return Vue.moment(el.time).format('MMM D')
                            }
                            if (timeRange === 'year') {
                                return Vue.moment(el.time).format('MMM')
                            }
                        })

                        const results = _.mapObject(grouped, function (items) {
                            return items.length
                        })
                        // console.log(results)
                        // console.log(results);
                        let total = 0
                        let revenue = 0
                        const productList = {}
                        items.forEach(element => {
                            // if( Vue.moment().isSame(element.time, state.currentDateFilter))
                            total += +element.total
                            if (element.items && type === 'sales') {
                                element.items.forEach(item => {
                                    revenue += (+item.price - +item.purchase) * +item.amount
                                    if (productList[item.id]) {
                                        productList[item.id].amount = productList[item.id].amount + item.amount
                                    } else {
                                        productList[item.id] = {
                                            id: item.id,
                                            name: item.name,
                                            amount: item.amount,

                                        }
                                    }
                                })
                            }
                        })

                        const productArray = Object.values(productList)
                        productArray.sort((a, b) => +b.amount - +a.amount)
                        // console.log(productArray, productList);
                        const format = {
                            data: {
                                lastElement: items.slice(-1) || null,
                                revenue: revenue || null,
                                total: total || null,
                                list: items,
                                results: results || [],
                                labels: Object.keys(results),
                                series: Object.values(results),
                                hotSales: productArray.slice(0, 10),
                            },
                        }
                        commit(loadCommit, format)
                        // console.log(grouped, format);
                        commit('SET_LOADING', false)
                        resolve()
                    }, function (error) {
                        commit('SET_LOADING', false)
                        console.error(error)
                        reject()
                    },
                )
            })
        },
        setFilterCriteria ({ commit }, payload) {
            commit('SET_FILTER_CRITERIA', payload)
        },
        setCurrentShop ({ commit, dispatch }, payload) {
            // console.log(payload)
            commit('SET_CURRENT_SHOP', payload)
            // dispatch('loadCharts');
        },
        clearCurrentShop ({ commit, dispatch }) {
            commit('SET_CURRENT_SHOP', null)
        },
        loadLog ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                // console.log(payload);
                let userId = '' 
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let path
                let loadCommit
                const type = payload[0]
                commit('SET_CURRENT_TYPE', type)
                const from = payload[1]
                const to = payload[2]
                if (type === 'all') {
                    path = `users/${userId}/transactions`
                } else {
                    path = `users/${userId}/transactions/${type}`
                }

                const fireDB = firebase.database().ref().child(path)
                switch (type) {
                    case 'purchases':
                        loadCommit = 'SET_PURCHASES_LIST'
                        break
                    case 'sales':
                        loadCommit = 'SET_SALES_LIST'
                        break
                    case 'transfers':
                        loadCommit = 'SET_TRANSFERS_LIST'
                        break
                    case 'all':
                        loadCommit = 'SET_TRANSACTION_lIST'
                        break
                }

                let fireRef
                // console.log(from, to);
                if (type === 'all') {
                    fireRef = fireDB
                } else {
                    if (from && to) {
                        fireRef = fireDB.orderByChild('time').startAt(from).endAt(to)
                    } else if (!from || !to) {
                        fireRef = from ? fireDB.orderByChild('time').startAt(from) : to ? fireDB.orderByChild('time').endAt(to) : fireDB
                    }
                }
                fireRef.on('value',
                    function (data) {
                        const items = []
                        const itemList = data.val() || {}
                        Object.keys(itemList).forEach(function (key) {
                            items.push({
                                ...itemList[key],
                            })
                        })
                        commit('SET_LOADING', false)

                        commit(loadCommit, items)
                        // console.log(items);
                        resolve()
                    }, function (error) {
                        commit('SET_LOADING', false)
                        console.error(error)
                        reject()
                    },
                )
            })
        },
        async sendBill ({ commit, state, rootState }, payload) {
            let userId = '' 
            if(rootState.users.user.adminUid){
                userId = rootState.users.user.adminUid
            }else{
                userId = rootState.users.user.uid
            }
            commit('SET_LOADING', true)
            const transactionKey = firebase.database().ref().child(`users/${userId}/transactions/${payload.type}`).push().key
            payload.id = transactionKey
            await firebase.database().ref().child(`users/${userId}/transactions/${payload.type}/${transactionKey}`).update(payload).then(function () {
                setTimeout(function () {
                    commit('SET_LOADING', false)
                }, 500)
            })
        },

    },

    getters: {
        getTodaySales: (state, getters) => (shop) => {
            const shopList = state.allDaySales.filter(function (el) {
                return el.location_from.id === shop.id
            })
            const grouped = _.groupBy(shopList, function (el) {
                return Vue.moment(el.time).format('ha')
            })
            const results = _.mapObject(grouped, function (items) {
                return items.length
            })

            let total = 0
            let revenue = 0
            const productList = {}
            shopList.forEach(element => {
                // if( Vue.moment().isSame(element.time, state.currentDateFilter))
                total += +element.total
                if (element.items && type === 'sales') {
                    element.items.forEach(item => {
                        revenue += (+item.price - +item.purchase) * +item.amount
                        if (productList[item.id]) {
                            productList[item.id].amount = productList[item.id].amount + item.amount
                        } else {
                            productList[item.id] = {
                                id: item.id,
                                name: item.name,
                                amount: item.amount,

                            }
                        }
                    })
                }
            })
            const productArray = Object.values(productList)
            productArray.sort((a, b) => +b.amount - +a.amount)
            // console.log(productArray, productList);
            return {
                data: {
                    lastElement: shopList.slice(-1) || null,
                    revenue: revenue || null,
                    total: total || null,
                    list: shopList,
                    results: results || [],
                    labels: Object.keys(results),
                    series: Object.values(results),
                    hotSales: productArray.slice(0, 10),
                },
            }
        },
        getDailyPurchasesChart: (state, getters) => {
            return state.purchasesDayList
        },
        getWeeklyPurchasesChart: (state, getters) => {
            return state.purchasesWeekList
        },
        getMonthlyPurchasesChart: (state, getters) => {
            return state.purchasesMonthList
        },
        getYearlyPurchasesChart: (state, getters) => {
            return state.purchasesYearList
        },
        getDailySalesChart: (state, getters) => {
            return state.salesDayList
        },
        getWeeklySalesChart: (state, getters) => {
            return state.salesWeekList
        },
        getMonthlySalesChart: (state, getters) => {
            return state.salesYearList
        },
        getCurrentType: (state, getters) => {
            return state.currentType
        },
        getLog (state, rootGetters) {
            let transactions

            const type = state.currentType
            switch (type) {
                case 'sales':
                    transactions = state.sales
                    break
                case 'purchases':
                    transactions = state.purchases
                    break
                case 'transfers':
                    transactions = state.transfers
                    break
                case 'all':
                    transactions = [...state.sales, ...state.purchases, ...state.transfers]
                    break
            }

            if (state.criteria !== '') {
                return transactions.filter((el) => {
                    if (el.items) {
                        for (let i = 0; i < el.items.length; i++) {
                            if (el.items[i].name.includes(state.criteria)) {
                                return true
                                break
                            }
                        }
                    }
                })
            } else { return transactions }
        },

        getTransactionsTotal: (state, getters) => {
            let total = 0
            if (getters.getLog) {
                const list = getters.getLog || {}
                list.forEach(element => {
                    // if( Vue.moment().isSame(element.time, state.currentDateFilter))
                    total += +element.total
                })
                return total
            }
            return 0
        },
        getRevenueTotal: (state, getters) => {
            let total = 0
            if (getters.getLog) {
                const list = getters.getLog || {}
                list.forEach(element => {
                    if (element.items) {
                        element.items.forEach(item => {
                            if (item.purchase) {
                                total += (+item.price - +item.purchase) * +item.amount
                            }
                        })
                    }
                })
                return total
            }
            return 0
        },

        getAllTransactions (state) {
            return state.transactions
        },
        getTransactionType ({ state }, type) {
            switch (type) {
                case 'all':
                    return state.transactions
                    break
                case 'sales':
                    return state.sales
                    break
                case 'purchases':
                    return state.purchases
                    break
                case 'transfers':
                    return state.transfers
                    break
            }
        },
        getProductLog (state) {
            const productLog = []
            let transactions

            switch (state.currentType) {
                case 'sales':
                    transactions = state.sales
                    break
                case 'purchases':
                    transactions = state.purchases
                    break
                case 'transfers':
                    transactions = state.transfers
                    break
                case 'all':
                    transactions = [...state.sales, ...state.purchases, ...state.transfers]
                    break
            }

            try {
                const log = transactions.filter((el) => {
                    for (let i = 0; i < el.items.length; i++) {
                        if (el.items[i].name.includes(state.currentItem.name)) {
                            return true
                            break
                        }
                    }
                })

                log.forEach(item => {
                    item.items.forEach(el => {
                        if (el.name.includes(state.currentItem.name)) {
                            productLog.push(
                                {
                                    location: item.location,
                                    name: el.name,
                                    time: item.time,
                                    type: item.type,
                                    amount: +el.amount,
                                    purchase: +el.purchase,
                                    price: +el.price,
                                    total: +el.price * el.amount | 0,
                                    utility: (+el.price - +el.purchase) * 100 / +el.price,
                                },
                            )
                        }
                    })
                })
                // console.log(log);
                return productLog
            } catch (err) {
                // console.log(err);
                //
            }
        },

        getItems (state) {
            return state.shops_list
        },
        getCurrentItem (state) {
            return state.currentItem
        },
        getLoading (state) {
            return state.isLoading
        },
    },

}
