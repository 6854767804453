/* eslint-disable */
export const loading = {
    namespaced: true,
    state: {
        loading: false,
    },
    mutations: {
        SET_LOADING (state, payload) {
            state.loading = payload
        },
    },

    actions: {
        setLoading ({ commit }, payload) {
            // console.log(payload)
            commit('SET_LOADING', payload)
        },

    },
    getters: {
        getLoading (state) {
          return state.loading
        },
    },

}
