/* eslint-disable */
export const dialogs = {
    namespaced: true,
    state: {

        loginDialog: false,
        registerDialog: false,
        createDialog: false,
        createProductDialog: false,
        createShopDialog: false,
        createCategoryDialog: false,
        createTransferDialog: false,
        createPurchaseDialog: false,
        currentItem: null,
    },
    mutations: {
        SET_LOGIN_DIALOG (state, payload) {
          state.loginDialog = payload
        },
        SET_REGISTER_DIALOG (state, payload) {
          state.registerDialog = payload
        },
        SET_CREATE_PRODUCT_DIALOG (state, payload) {
            state.createProductDialog = payload
        },
        SET_CREATE_CATEGORY_DIALOG (state, payload) {
            state.createCategoryDialog = payload
        },
        SET_CREATE_SHOP_DIALOG (state, payload) {
            state.createShopDialog = payload
        },
        SET_TRANSFER_DIALOG (state, payload) {
            state.createTransferDialog = payload
        },
        SET_PURCHASE_DIALOG (state, payload) {
            state.createPurchaseDialog = payload
        },
        SET_CREATE_DIALOG (state, payload) {
            state.createDialog = payload
        },
        SET_CURRENT_DIALOG (state, payload) {
            state.currentItem = payload
        },

    },

    actions: {
        openDialog ({ commit, state }, payload) {
            let setCommit

            switch (payload) {
                case 'login':
                  setCommit = 'SET_LOGIN_DIALOG'
                  break
                case 'register':
                  setCommit = 'SET_REGISTER_DIALOG'
                  break
                case 'createProduct':
                    setCommit = 'SET_CREATE_PRODUCT_DIALOG'
                    break
                case 'createCategory':
                    setCommit = 'SET_CREATE_CATEGORY_DIALOG'
                    break
                case 'createShop':
                    setCommit = 'SET_CREATE_SHOP_DIALOG'
                    break
                case 'createTransfer':
                    setCommit = 'SET_TRANSFER_DIALOG'
                    break
                case 'createPurchase':
                    setCommit = 'SET_PURCHASE_DIALOG'
                    break
            }
            commit('SET_CURRENT_DIALOG', payload)
            commit(setCommit, true)
        },
        closeDialog ({ commit }, payload) {
            commit('SET_CURRENT_DIALOG', null)
        },
        openCreateDialog ({ commit, state }) {
            if (state.createDialog) { commit('SET_CREATE_DIALOG', false) } else commit('SET_CREATE_DIALOG', true)
        },
        closeCreateDialog ({ commit, state }) {
            if (state.createDialog === true) { commit('SET_CREATE_DIALOG', false) }
        },

    },
    getters: {
        getDialog (state, payload) {
            switch (payload) {
                case 'login':
                    return state.loginDialog
                    break
                case 'register':
                    return state.registerDialog
                    break
                case 'createProduct':
                    return state.createProductDialog
                    break
                case 'createCategory':
                    return state.createCategoryDialog
                    break
                case 'createShop':
                    return state.createShopDialog
                    break
                case 'createTransfer':
                    return state.createTransferDialog
                    break
                case 'createPurchase':
                    return state.createPurchaseDialog
                    break
            }
        },
        getCurrentItem (state) {
          return state.currentItem
        },
        getCreateDialog (state) {
            return state.createDialog
        },
    },

}
