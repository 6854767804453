/* eslint-disable */

export const drawer = {
  namespaced: true,
  state: {
    barColor: '#808184',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
  },
  actions: {
    set_drawer({commit}, payload){
      // console.log(payload)
      commit('SET_DRAWER', payload)
    }
  },
  getters:{

  }

}
