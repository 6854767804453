import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'
import store from './store/index'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    // Login register
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock'),
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Pricing',
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
        },
      ],
    },
    // Dashboard
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Home',
          path: '/',
          component: () => import('@/views/dashboard/HomePage'),
        },
        {
          name: 'Search',
          path: '/search',
          component: () => import('@/views/custom/Search'),
          children: [
            {
              path: ':category',
              components: {
                default: import('@/views/custom/Search'),
              },
            },
            {
              path: ':category/:subcategory',
              components: {
                default: import('@/views/custom/Search'),
              },
            },
          ],
        },
        {
          name: "Faq's",
          path: '/faq',
          component: () => import('@/views/custom/Faq'),
        },
        {
          name: 'About us',
          path: '/about-us',
          component: () => import('@/views/custom/About'),
        },
        {
          name: 'Careers',
          path: '/careers',
          component: () => import('@/views/custom/Careers'),
        },
        {
          name: 'News',
          path: '/news',
          component: () => import('@/views/custom/News'),
        },
        {
          name: 'Values',
          path: '/values',
          component: () => import('@/views/custom/Values'),
        },
        {
          name: 'Mission',
          path: '/mission',
          component: () => import('@/views/custom/Mission'),
        },
        {
          name: 'Contact us',
          path: '/contact-us',
          component: () => import('@/views/custom/Contact'),
          children: [
            {
              name: 'Contact us',
              path: ':subjects',
              components: {
                default: import('@/views/custom/Contact'),
              },
            },
          ],
        },
        {
          name: 'Terms and Conditions',
          path: '/terms-and-conditions',
          component: () => import('@/views/custom/Terms'),
          showName: false,
        },
      ],
    },
    // Businesses
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Home',
          path: '/',
          component: () => import('@/views/dashboard/HomePage'),
        },
        {
          name: 'Collaborators',
          path: '/collaborators',
          component: () => import('@/views/custom/Collaborators'),
        },
        {
          name: 'Subscriptions',
          path: '/subscriptions',
          component: () => import('@/views/custom/Subscriptions/Planner'),
        },
        {
          name: 'Plans',
          path: '/plans',
          component: () => import('@/views/custom/Subscriptions/Plans'),
        },
        {
          name: 'Profile',
          path: '/profile',
          component: () => import('@/views/custom/UserProfile'),
        },
        {
          name: 'Purchased content',
          path: '/videos',
          component: () => import('@/views/custom/Videos'),
        },
        {
          name: 'Notifications',
          path: '/notifications',
          component: () => import('@/views/custom/Notifications'),
        },
        {
          name: 'Success buy',
          path: '/success/:id',
          component: () => import('@/views/custom/webcheckout/Success'),
        },
        {
          name: 'Cancel buy',
          path: '/cancel',
          component: () => import('@/views/custom/webcheckout/Cancel'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    // contributor
    {
      path: '/contributor',
      component: () => import('@/views/contributor/Index'),
      children: [
        // Home page for contributor
        {
          name: 'Home',
          path: '/',
          component: () => import('@/views/contributor/HomePage'),
        },
        // Profile
        {
          name: 'Profile',
          path: 'profile',
          component: () => import('@/views/contributor/UserProfile'),
        },
        // Camara
        {
          name: 'Camera',
          path: 'camera',
          component: () => import('@/views/contributor/component/Camera'),
        },
        {
          name: 'My Uploads',
          path: 'videos',
          component: () => import('@/views/contributor/custom/Videos'),
        },
        {
          name: 'Notifications',
          path: 'notifications',
          component: () => import('@/views/custom/Notifications'),
        },
        // drawer
        {
          name: "Faq's",
          path: 'faq',
          component: () => import('@/views/custom/Faq'),
        },
        {
          name: 'About us',
          path: 'about-us',
          component: () => import('@/views/custom/About'),
        },
        {
          name: 'Careers',
          path: 'careers',
          component: () => import('@/views/custom/Careers'),
        },
        {
          name: 'News',
          path: 'news',
          component: () => import('@/views/custom/News'),
        },
        {
          name: 'Values',
          path: 'values',
          component: () => import('@/views/custom/Values'),
        },
        {
          name: 'Mission',
          path: 'mission',
          component: () => import('@/views/custom/Mission'),
        },
        {
          name: 'Contact us',
          path: 'contact-us',
          component: () => import('@/views/custom/Contact'),
          children: [
            {
              name: 'Contact us',
              path: ':subjects',
              components: {
                default: import('@/views/custom/Contact'),
              },
            },
          ],
        },
        {
          name: 'Terms and Conditions',
          path: 'terms-and-conditions',
          component: () => import('@/views/custom/Terms'),
          showName: false,
        },
        {
          name: 'Camera',
          path: 'camera',
          component: () => import('@/views/custom/Camera'),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (await firebase.auth().currentUser || store.state.users.user) {
        next()
      } else {
        next('/pages/login')
      }
    } else {
      next()
    }
})

export default router
