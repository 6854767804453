/* eslint-disable */
import {
  auth , fireDb
} from '@/plugins/auth/firebase.js'
import firebase from 'firebase'
import Cookie from 'js-cookie'
import JWTDecode from 'jwt-decode'
// import router from '@/router/index.js'

export const users = {
  namespaced: true,
  state: {
    user: null,
    isLoading: false,
    profile: null,
    isMobile: false,
    loadingContact: false,
  },
  mutations: {
    SET_USER: (state, account) => {
      state.user = account
    },
    SET_LOADING: (state, payload) => {
      state.isLoading = payload
    },
    SET_LOADING_CONTACT: (state, payload) => {
      state.loadingContact = payload
    },
    SET_PROFILE: (state, payload) => {
      state.profile = payload
    },
    SET_MOBILE: (state, payload) => {
      state.isMobile = payload
    },
  },

  actions: {
    clearUser({
      commit
    }) {
      commit('SET_USER', null)
      commit('SET_PROFILE', null)
    },
    async login({
      dispatch,
      commit
    }, account) {
      // console.log(account)
      commit('SET_LOADING', true)
      try {
        await auth.signInWithEmailAndPassword(account.email, account.password).then(async function (res) {
          await auth.currentUser.getIdTokenResult().then(async (idTokenResult) => {
            // Confirm the user is an Admin.
            // console.log(idTokenResult)
            if (idTokenResult.claims.role === 'Colaborador') {
              const token = idTokenResult.token
              // const user = {email: idTokenResult.claims.email, uid: idTokenResult.claims.adminUid, role:idTokenResult.claims.role}
              // Cookie.set('access_token', {token, adminUid: idTokenResult.claims.adminUid, role:idTokenResult.claims.role});
              const user = {
                email: idTokenResult.claims.email,
                uid: idTokenResult.claims.user_id,
                role: idTokenResult.claims.role,
                adminUid: idTokenResult.claims.adminUid || null,
              }
              const cookie = {
                token: token,
                uid: user.uid,
                adminUid: user.adminUid,
                role: user.role,
              }
              // console.log(user)
              Cookie.set('access_token', cookie)
              await commit('SET_USER', user)
              commit('SET_LOADING', false)
            } else if (!idTokenResult.claims.role) {
              const token = idTokenResult.token
              const {
                email,
                uid
              } = auth.currentUser
              Cookie.set('access_token', {
                token,
                adminUid: null
              })
              await commit('SET_USER', {
                email,
                uid
              })
              commit('SET_LOADING', false)
            } else {
              commit('SET_LOADING', false)
            }
          })

        })
      } catch (error) {
        // console.log(error)
        commit('SET_LOADING', false)
        throw error;
      }
    },
    setCookie({
      dispatch,
      commit
    }, token) {
      Cookie.set('access_token', {
        token,
        adminUid: null
      })
    },
    async register({
      dispatch,
      commit
    }, account) {
      return new Promise((resolve, reject) => {
        auth.createUserWithEmailAndPassword(account.email, account.password)
          .then(function (res) {
            // console.log(res)
            resolve();
          }).catch((error) => {
            // console.log(error)
            commit('SET_LOADING', false)
            reject(error)
          })
      })
    },
    async forgotPass({
      dispatch,
      commit
    }, account) {
      try {
        // // console.log(account);
        await auth.sendPasswordResetEmail(account.email)
      } catch (error) {
        throw error
      }
    },
    loadProfile({
      commit,
      state
    }) {
      console.log(state.user)
      let uid = state.user.uid
      commit('SET_LOADING', true)
      return new Promise((resolve, reject) => {
        fireDb.ref(`users/${uid}/profile`).on('value',
          function (snapshot) {
            let loadCommit = 'SET_PROFILE'
            let profile = snapshot.val()
            console.log('profile', profile)
            commit(loadCommit, profile)
            commit('SET_LOADING', false)
            resolve(profile)
          },
          function (error) {
            console.log(error)
            commit('SET_LOADING', false)
            reject()
          },
        )
      })
    },
    async updateProfile({
      commit,
      state
    }, payload) {
      commit('SET_LOADING', true)
      try {
        let uid = state.user.uid
        if (payload.complete) {
          // Send notification
          const notification = {
            created: firebase.database.ServerValue.TIMESTAMP,
            title: 'Profile completed',
            description: `Your profile information has been completed`,
            read: false,
            type: 'welcome'
          }
          const key = fireDb.ref().child(`users/${uid}/notifications`).push().key
          let updates = {}
          updates[`users/${uid}/notifications/${key}`] = notification
          await fireDb.ref().update(updates)
          delete payload.complete;
        }
        await fireDb.ref(`users/${uid}/profile`).update(payload).then(async function (res) {
          commit('SET_LOADING', false)
        }).catch(function (err) {
          commit('SET_LOADING', false)
          throw err
        })
      } catch (error) {
        commit('SET_LOADING', false)
        throw error
      }
    },
    async sendContact({
      commit,
      state
    }, payload) {
      commit('SET_LOADING_CONTACT', true)
      const itemdata = {
        created: firebase.database.ServerValue.TIMESTAMP,
        ...payload
      }
      try {
        await fireDb.ref().child(`admin/contacts/`).push(itemdata)
        commit('SET_LOADING_CONTACT', false)
      } catch (error) {
        commit('SET_LOADING_CONTACT', false)
        throw error
      }
    },
    loadCookies({
      commit
    }) {
      // const parsed = cookieparser.parse(req.headers.cookie);
      // const accessTokenCookie = parsed.access_token;
      // // console.log('access_token : ', Cookie.get('access_token'));
      const accessTokenCookie = Cookie.get('access_token')
      if (!accessTokenCookie) return
      const decoded = JWTDecode(accessTokenCookie)
      // console.log(decoded)
      if (decoded) {
        if (decoded.role) {
          commit('SET_USER', {
            uid: decoded.user_id,
            email: decoded.email,
            role: decoded.role,
            adminUid: decoded.adminUid
          })
        } else {
          commit('SET_USER', {
            uid: decoded.user_id,
            email: decoded.email,
          })
        }
      }
    },
  },
  getters: {
    getLoading(state) {
      return state.isLoading
    },
    getLoadingContact(state) {
      return state.loadingContact
    },
    getIsMobile(state) {
      return state.isMobile
    }
  },

}
