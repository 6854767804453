/* eslint-disable */
import { auth } from '@/plugins/auth/firebase'
// import Cookie from 'js-cookie'
// import JWTDecode from 'jwt-decode'

export const uploads = {
    namespaced: true,
    state: {
        uploads_list: [],
        currentItem: {},
        isLoading: false,
        media_list: [],
    },

    mutations: {

        SET_UPLOADS_LIST (state, payload) {
            state.uploads_list = payload
        },
        SET_MEDIA_LIST (state, payload) {
            state.media_list = payload
        },
        SET_LOADING (state, payload) {
            // console.log(payload);
            state.isLoading = payload
        },
        SET_CURRENT_ITEM (state, payload) {
            // console.log(payload)
            state.currentItem = payload
        },
    },

    actions: {
        async loadUploads ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let path = `users/${userId}/uploads`
                let loadCommit ='SET_UPLOADS_LIST'
                firebase.database().ref(path).orderByChild('name').on('value',
                function (snapshot) {
                    let items = null;
                    if (snapshot.val()) {
                        items = []
                        snapshot.forEach(function (child) {
                            items.push({
                                ...child.val(),
                                id: child.key
                            });
                        });
                    }
                    commit('SET_LOADING', false)
                    commit(loadCommit, items)
                    resolve()
                }, function (error) {
                    console.error(error)
                    commit('SET_LOADING', false)
                    reject()
                },
            )
        })
        },
        async loadMedia ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let path = `media/`
                let loadCommit ='SET_MEDIA_LIST'
                firebase.database().ref(path).orderByChild('name').on('value',
                function (snapshot) {
                    let items
                    if (snapshot.val()) {
                        items = []
                        snapshot.forEach(function (child) {
                            items.push({
                                ...child.val(),
                                id: child.key
                            });
                        });
                    }
                    // console.log(items)
                    commit(loadCommit, items)
                    resolve()
                }, function (error) {
                    console.error(error)
                    reject()
                },
            )
        })
        },
        setCurrentItem ({ commit, state, rootState }, payload) {
            // console.log(payload)
            commit('SET_LOADING', true);
             let vm = this;
             let userId = rootState.users.user.uid;
             if(payload){
                 return new Promise((resolve, reject) => {
                 let path = `users/${userId}/collaborators`;
                 let setCommit = 'SET_CURRENT_ITEM';
                 let id;
                 try{
                     let currentItem = state.collaborators_list.find(item => item.id === payload.id);
                     // console.log(currentItem);
                     if(currentItem){
                        commit('SET_LOADING', false);
                        commit(setCommit, currentItem);
                        resolve();
                     }else{
                        let dbRef = firebase.database().ref();
                        let item;
                        dbRef.child(path+'/' + id).on('value', function (data) {
                            // console.log(data.val(), id);
                            item = {
                                ...data.val(),
                                id: data.key
                            };
                            commit(setCommit, item);
                            commit('SET_LOADING', false);
                            resolve();
                        }, function (error) {
                            // console.log(error);
                            commit('SET_LOADING', false);
                            reject();
                        })
                     }
                 }catch(err){
                     reject();
                     // console.log(err);
                 }
             })
             }
             else{
                 commit('SET_CURRENT_ITEM', {});
             }
        },
        async createUpload ({ commit, state, rootState }, payload){
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let price = 0
                if(payload.type.includes('image')){
                    price = 99.95
                } else {
                    price = 149.95
                }
                const key = payload.id ? payload.id : firebase.database().ref().child(`users/${userId}/uploads`).push().key
                const itemData = {
                    ...payload,
                    created: firebase.database.ServerValue.TIMESTAMP,
                    price: price,
                    status: 'pending'
                }
                // console.log(itemData)
                const updates = {}
                updates[`users/${userId}/uploads/${key}`] = itemData
                firebase.database().ref().update(updates).then(function () {
                    // Insertar en instancia dle administrador
                    let media = {
                        created: firebase.database.ServerValue.TIMESTAMP,
                        owner: userId,
                        ownerName: rootState.users.profile.first_name,
                        ...itemData
                    }
                    firebase.database().ref(`admin/uploads/${key}`).update(media).then(function () {
                        commit('SET_LOADING', false)
                        resolve()
                    }).catch(function (err) {
                        commit('SET_LOADING', false)
                        reject(err)
                    })
                }).catch(function (err) {
                    commit('SET_LOADING', false)
                    reject(err)
                })
            
            })
        },
        async updateUpload ({ commit, state, rootState }, payload){
            return new Promise((resolve, reject) => {
                console.log(payload)
                commit('SET_LOADING', true)
                // console.log(payload)
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                // Set price
                let price = 0
                if(payload.type.includes('image')){
                    price = 99.95
                } else {
                    price = 149.95
                }
                // Change status and price
                const itemData = {
                    ...payload
                }
                itemData.status = 'pending'
                itemData.price = price
                console.log(itemData)
                const updates = {}
                updates[`users/${userId}/uploads/${itemData.id}`] = itemData
                firebase.database().ref().update(updates).then(function () {
                    // Insertar en instancia dle administrador
                    let media = {
                        owner: userId,
                        ownerName: rootState.users.profile.first_name,
                        ...itemData
                    }
                    firebase.database().ref(`admin/uploads/${itemData.id}`).update(media).then(function () {
                        commit('SET_LOADING', false)
                        resolve()
                    }).catch(function (err) {
                        commit('SET_LOADING', false)
                        reject(err)
                    })
                }).catch(function (err) {
                    commit('SET_LOADING', false)
                    reject(err)
                })
            })
        }

    },
    getters: {
        getItems (state) {
            return state.collaborators_list
        },
        getMedia (state) {
            return state.media_list
        },
        getCurrentItem (state) {
            return state.currentItem
        },
        getLoading (state) {
            return state.isLoading
        },
    },

}
