import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#B2262D',
  secondary: '#337F9D',
  accent: '#123E69',
  accentTwo: '#113961;',
  info: '#46A4BB',
  neutro: '#808184',
  transparent: 'transparent',
  success: '#337F9D',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
