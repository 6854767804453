/* eslint-disable */
import { auth } from '@/plugins/auth/firebase.js'
import Cookie from 'js-cookie'
import JWTDecode from 'jwt-decode'
import Vue from 'vue'

export const idle = {
    namespaced: true,
    state: {
        isIdle: false,
        user: {},
    },
    mutations: {
        SET_IDLE: (state, payload) => {
            state.isIdle = payload
        },
        SET_USER: (state, payload) => {
            state.user = payload
        },
    },

    actions: {
        clearUser ({ commit }) {
            commit('SET_USER', null)
        },
        setCurrentItem ({ commit, rootState }, payload) {
            if (payload) {
                const user = {
                    data: rootState.users.user,
                    image: rootState.users.profile.image,
                    session_pass: rootState.users.profile.session_pass
                }
                if(user) commit('SET_USER', user)
            } else {
                commit('SET_USER', {})
            }
        },
    },
}

