<template lang="pug">
  div(:class="`page-${$route.name}`" class="deeply")
    v-dialog(v-if='isIdle && isAuth' persistent  :value='isIdle'  max-width='400px' transition="dialog-bottom-transition")
      div(style ='position:relative; height: 100%;')
        idleDialog(v-if='isIdle && isAuth' @close="isIdle=false")
    router-view()
</template>

<script>
/* eslint-disable */

  export default {
    name: 'App',

    components: {
      idleDialog: () => import('@/components/custom/dialogs/IdleDialog.vue'),
      BottomNavContributor: () => import('@/views/contributor/components/core/BottomNav.vue'),
      BottomNav: () => import('@/views/dashboard/components/core/BottomNav.vue'),
    },

    data () {
      return {
        time: 10000,
        itemsBottomNavContributor: [
          {
            to: '/',
            name: '',
            icon: require('@/assets/images/icon_home.png'),
            iconActive: require('@/assets/images/icon_home_active.png')
          },
          {
            to: '/videos',
            name: '',
            icon: require('@/assets/images/icon_video.png'),
            iconActive: require('@/assets/images/icon_video_active.png')
          },
          {
            to: '/search',
            name: '',
            icon: require('@/assets/images/icon_camera.png'),
            iconActive: require('@/assets/images/icon_camera.png')
          },
          {
            to: '/notifications',
            name: '',
            icon: require('@/assets/images/icon4.png'),
            iconActive: require('@/assets/images/icon4_active.png')
          },
          {
            to: '/profile',
            name: '',
            icon: require('@/assets/images/icon5.png'),
            iconActive: require('@/assets/images/icon5_active.png')
          },
        ],
        itemsBottomNavBussiness: [
          {
            to: '/',
            name: '',
            icon: require('@/assets/images/icon1.png'),
            iconActive: require('@/assets/images/icon1_active.png')
          },
          {
            to: '/videos',
            name: '',
            icon: require('@/assets/images/icon_video.png'),
            iconActive: require('@/assets/images/icon_video_active.png')
          },
          {
            to: '/search',
            name: '',
            icon: require('@/assets/images/icon3.png'),
            iconActive: require('@/assets/images/icon3.png')
          },
          {
            to: '/notifications',
            name: '',
            icon: require('@/assets/images/icon4.png'),
            iconActive: require('@/assets/images/icon4_active.png')
          },
          {
            to: '/profile',
            name: '',
            icon: require('@/assets/images/icon5.png'),
            iconActive: require('@/assets/images/icon5_active.png')
          },
        ],
        showUpgradeUI: false,
      }
    },

    computed: {
      rol () {
        return this.$store.getters['users/getRole']
      },
      windowWidth () {
        return window.innerWidth
      },
      isAuth () {
        return !!this.$store.state.users.user
      },
      isIdle () {
        return this.$store.state.idleVue.isIdle
      },
      profileRoles () {
        const rol = this.$store.state.users.profile ? this.$store.state.users.profile.roles[0] : ''
        return rol;
      },
    },

    watch: {
      isAuth: function (val) {
        if (val) {
          // console.log(val)
          // this.activateActivityTracker()
          if (this.$route.params.pathMatch !== 'login' && this.$route.params.pathMatch !== 'register') {
            // entra si no es necesaria redireccion
          } else {
            //this.$router.replace('profile').catch(err => {})
          }
        } else {
          //this.$router.replace('login').catch(err => {})
        }
      },
    },

    async created () {
      const vm = this
      vm.loadCookies()
      if (this.$workbox) {
        this.$workbox.addEventListener("waiting", () => {
          this.showUpgradeUI = true;
        });
      }
    },
    mounted(){
    },
    methods: {
      async accept() {
        this.showUpgradeUI = false
        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
      },
      loadCookies () {
        this.$store.dispatch('users/loadCookies')
      },
    },

  }
</script>
<style lang="scss">
  .footer-core{
    margin-top: auto;
  }
  .main-content{
    padding-bottom: 50px;
    padding-top: 53px !important;
    & > .v-content__wrap{
      display: flex;
      flex-direction: column !important;
    }
  }
  .custom-avatar{
    border-radius: 50%
  }
  .custom-card-profile__avatar img {
    object-fit: cover !important;
  }
 #app, html{
    overflow-x: hidden;
  }
  .page-Search{
    overflow-x: hidden;
  }
  .v-dialog--active{
    box-shadow: none !important;
  }
  .custom-bottom-nav__nav{
    // background-color: #bada55;
  }
  .pac-container.pac-logo.hdpi{
    z-index: 9999 !important;
  }
</style>
