/* eslint-disable */
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'

//TEST
const configTest = {
  apiKey: "AIzaSyCYl6C2CeoI3cXyAJP_9DZM7ff-c_G4SZ4",
  authDomain: "scenefinder-dev.firebaseapp.com",
  databaseURL: "https://scenefinder-dev.firebaseio.com",
  projectId: "scenefinder-dev",
  storageBucket: "scenefinder-dev.appspot.com",
  messagingSenderId: "925693496520",
  appId: "1:925693496520:web:37063210f41c97dbaa69fd",
  measurementId: "G-1HJKB84PG9"
}

//PROD
const config = {
  apiKey: "AIzaSyAHDCAz2Ui4rHi3Dj8IH9W_ZbAvcrWSlG8",
  authDomain: "scene-finder-3cb7a.firebaseapp.com",
  databaseURL: "https://scene-finder-3cb7a.firebaseio.com",
  projectId: "scene-finder-3cb7a",
  storageBucket: "scene-finder-3cb7a.appspot.com",
  messagingSenderId: "972112353767",
  appId: "1:972112353767:web:172a11202f8b4dd4698542",
  measurementId: "G-1YDS7J70H3"
}

if (!firebase.apps.length) {
  firebase.initializeApp(configTest)
}

const currentUser = firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  })
};

const fireDb = firebase.database()
const fireStorage = firebase.storage()
const auth = firebase.auth()
export { fireDb, fireStorage, auth, currentUser }
/* eslint-enable */
