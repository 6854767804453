/* eslint-disable */
import { auth } from '@/plugins/auth/firebase'
// import Cookie from 'js-cookie'
// import JWTDecode from 'jwt-decode'

export const downloads = {
    namespaced: true,
    state: {
        downloads_list: [],
        isLoading: false,
        tokens: false,
    },

    mutations: {

        SET_DOWNLOADS_LIST (state, payload) {
            state.downloads_list = payload
        },
        SET_LOADING (state, payload) {
            state.isLoading = payload
        },
        SET_TOKENS (state, payload) {
            state.tokens = payload
        },
    },

    actions: {
        async loadDownloads ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                let path = `users/${userId}/downloads`
                let loadCommit ='SET_DOWNLOADS_LIST'
                firebase.database().ref(path).orderByChild('name').on('value',
                    function (snapshot) {
                        let items = null;
                        if (snapshot.val()) {
                            items = []
                            snapshot.forEach(function (child) {
                                items.push({
                                    ...child.val(),
                                    id: child.key
                                });
                            });
                        }
                        commit(loadCommit, items)
                        // Consultar cantidad de tokens
                        firebase.database().ref(`users/${userId}/tokens`).orderByChild('name').once('value',
                            function (snapshot) {
                                let tokens = {image: 0, video: 0}
                                if (snapshot.val()) {
                                    tokens = snapshot.val()
                                }
                                commit('SET_TOKENS', tokens)
                                commit('SET_LOADING', false)
                            }, function (error) {
                                commit('SET_LOADING', false)
                                reject()
                            },
                        )
                    }, function (error) {
                        console.error(error)
                        commit('SET_LOADING', false)
                        reject()
                    },
                )
            })
        },
        async createDownloads ({ commit, state, rootState }, payload){
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                const key = firebase.database().ref().child(`users/${userId}/downloads`).push().key
                const itemData = {
                    created: firebase.database.ServerValue.TIMESTAMP,
                    category: payload.category,
                    date: payload.date,
                    description: payload.description,
                    duration: payload.duration,
                    location: payload.location,
                    owner: payload.owner,
                    thumbnail: payload.thumbnail,
                    url: payload.url,
                    time: payload.time,
                    title: payload.title,
                    type: payload.type,
                    price: payload.price,
                    method: payload.method,
                }
                // console.log(itemData)
                const updates = {}
                updates[`users/${userId}/downloads/${key}`] = itemData
                firebase.database().ref().update(updates).then(function () {
                    commit('SET_LOADING', false)
                    resolve()
                }).catch(function (err) {
                    commit('SET_LOADING', false)
                    reject(err)
                })
            })
        },
        async createDownloadsCheckout ({ dispatch, state, rootState }, payload){
            return new Promise((resolve, reject) => {
                // console.log(payload)
                let found = state.downloads_list.find(element => element.url === payload.metadata)
                // console.log(found)
                let userId = ''
                if(rootState.users.user.adminUid){
                    userId = rootState.users.user.adminUid
                }else{
                    userId = rootState.users.user.uid
                }
                // Consultar si ya existen en descargas
                firebase.database().ref().child(`users/${userId}/downloads/`).orderByChild("url").equalTo(payload.metadata).once('value',
                    function (snapshot) {
                        if (snapshot.val()) {
                            // console.log('el video ya esta en downloads')
                        } else {
                            // Verificar en media
                            firebase.database().ref().child(`media/${payload.id}`).once('value',
                                function (snapshot) {
                                    // Insertar en descargas
                                    let item = {
                                        ...snapshot.val(),
                                        method:'card',
                                    }
                                    dispatch('createDownloads', item);
                                }, function (error) {
                                    // console.log(error)
                                },
                            )
                        }
                    }, function (error) {
                        // console.log(error)
                    },
                )
            })
        },

    },
    getters: {
        getLoading (state) {
            return state.isLoading
        },
        getDownloads (state) {
            return state.downloads_list
        },
        getTokens (state) {
            return state.tokens
        },
    },

}
