/* eslint-disable */
import { auth } from '@/plugins/auth/firebase'
// import Cookie from 'js-cookie'
// import JWTDecode from 'jwt-decode'

export const collaborators = {
    namespaced: true,
    state: {
        collaborators_list: [],
        currentItem: {},
        isLoading: false,
    },

    mutations: {

        SET_COLLABORATORS_LIST (state, payload) {
            state.collaborators_list = payload
        },
        SET_LOADING (state, payload) {
            // console.log(payload);
            state.isLoading = payload
        },
        SET_CURRENT_ITEM (state, payload) {
            // console.log(payload)
            state.currentItem = payload
        },
    },

    actions: {
        async loadCollaborators ({ commit, state, rootState }, type) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                const userId = rootState.users.user.uid
                // console.log(userId)
                let path
                let loadCommit
                switch (type) {
                    case 'collaborators':
                        path = `users/${userId}/collaborators`
                        loadCommit = 'SET_COLLABORATORS_LIST'
                        break
                }

                // console.log(loadCommit)

                // console.log(path)
                firebase.database().ref(path).orderByChild('name').on('value',
                function (snapshot) {
                    const items = []
                    snapshot.forEach(function (child) {
                        items.push({
                            ...child.val(),
                            id: child.key,
                        })
                    })
                    // console.log(items)
                    commit('SET_LOADING', false)
                    commit(loadCommit, items)
                    resolve()
                }, function (error) {
                    console.error(error)
                    commit('SET_LOADING', false)
                    reject()
                },
            )
        })
        },
        setCurrentItem ({ commit, state, rootState }, payload) {
            // console.log(payload)
            commit('SET_LOADING', true);
             let vm = this;
             let userId = rootState.users.user.uid;
             if(payload){
                 return new Promise((resolve, reject) => {
                 let path = `users/${userId}/collaborators`;
                 let setCommit = 'SET_CURRENT_ITEM';
                 let id;
                 try{
                     let currentItem = state.collaborators_list.find(item => item.id === payload.id);
                     // console.log(currentItem);
                     if(currentItem){
                        commit('SET_LOADING', false);
                        commit(setCommit, currentItem);
                        resolve();
                     }else{
                        let dbRef = firebase.database().ref();
                        let item;
                        dbRef.child(path+'/' + id).on('value', function (data) {
                            // console.log(data.val(), id);
                            item = {
                                ...data.val(),
                                id: data.key
                            };
                            commit(setCommit, item);
                            commit('SET_LOADING', false);
                            resolve();
                        }, function (error) {
                            // console.log(error);
                            commit('SET_LOADING', false);
                            reject();
                        })
                     }
                 }catch(err){
                     reject();
                     // console.log(err);
                 }
             })
             }
             else{
                 commit('SET_CURRENT_ITEM', {});
             }
        },
        deleteItem ({ commit, state, rootState }, payload) {
            // console.log(payload);
            return new Promise((resolve, reject) => {
                const userId = rootState.users.user.uid
                const id = payload
                commit('SET_LOADING', true)
                const promises = []
                promises.push(firebase.database().ref().child(`users/${userId}/collaborators/${id}`).remove())
                Promise.all(promises).then(function () {
                    commit('SET_LOADING', false)
                    resolve()
                }, function (error) {
                    commit('SET_LOADING', false)
                    // console.log(error)
                    reject()
                })
            })
        },
        async  updateItem ({ commit, state, rootState }, payload) {
            commit('SET_LOADING', true)
            const vm = this
            const userId = rootState.users.user.uid
            const itemData = payload
            await firebase.database().ref(`users/${userId}/collaborators/${itemData.id}`).update(itemData).then(function () {
                commit('SET_LOADING', false)
            },
            ).catch(function (err) {
                commit('SET_LOADING', false)
                throw err
            })
        },

    },
    getters: {
        getItems (state) {
            return state.collaborators_list
        },
        getCurrentItem (state) {
            return state.currentItem
        },
        getLoading (state) {
            return state.isLoading
        },
    },

}
