/* eslint-disable */
import axios from 'axios'
import * as vars from '@/vars.json';
export const admin = {
    namespaced: true,
    state: {
        uploads_pending: [],
        withdraw_list: [],
        isLoading: false,
    },

    mutations: {

        SET_UPLOADS_PENDING (state, payload) {
            state.uploads_pending = payload
        },
        SET_WITHDRAW_LIST (state, payload) {
            state.withdraw_list = payload
        },
        SET_LOADING (state, payload) {
            // console.log(payload);
            state.isLoading = payload
        },
    },

    actions: {
        async loadUploadsPending ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let path = `admin/uploads`
                let loadCommit ='SET_UPLOADS_PENDING'
                firebase.database().ref(path).orderByChild('status').equalTo('pending').once('value',
                    function (snapshot) {
                        let items =  []
                        if (snapshot.val()) {
                            snapshot.forEach(function (child) {
                                items.push({
                                    ...child.val(),
                                    id: child.key
                                });
                            });
                        }
                        commit(loadCommit, items)
                        resolve()
                    }, function (error) {
                        console.error(error)
                        reject()
                    },
                )
            })
        },

        async updateThumbnail ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                // Subir a media
                const newThumbanil = {
                    thumbnail: payload.thumbnail
                }
                firebase.database().ref(`users/${payload.owner}/uploads/${payload.id}`).update(newThumbanil).then(function () {
                    // Insertar notificacion
                    commit('SET_LOADING', false)
                    resolve()
                }).catch(function (err) {
                    commit('SET_LOADING', false)
                    reject(err)
                })
            })
        },
        
        async approveUploads ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true)
                // Subir a media
                let media = {
                    id: payload.id,
                    owner: payload.owner,
                    category: payload.category,
                    subcategory: payload.subcategory,
                    date: payload.date,
                    description: payload.description,
                    duration: payload.duration,
                    location: payload.location,
                    thumbnail: payload.thumbnail,
                    url: payload.url,
                    title: payload.title,
                    price: payload.price,
                    time: payload.time,
                    type: payload.type,
                    lastModified: payload.lastModified,
                    size: payload.size
                }
                firebase.database().ref(`media/${payload.id}`).update(media).then(function () {
                    // Actualizar uploads admin
                    let newStatus = {
                        status: 'approved'
                    }
                    firebase.database().ref(`admin/uploads/${payload.id}`).update(newStatus)
                    // Actualizar upload de usuario
                    firebase.database().ref(`users/${payload.owner}/uploads/${payload.id}`).update(newStatus).then(function () {
                        // Insertar notificacion
                        const key = firebase.database().ref().child(`users/${payload.owner}/notifications`).push().key
                        const notification = {
                            created: firebase.database.ServerValue.TIMESTAMP,
                            title: 'Media upload approved',
                            description: `Your media named ${media.title} was approved`,
                            read: false,
                            type: 'upload'
                        }
                        let updates = {}
                        updates[`users/${payload.owner}/notifications/${key}`] = notification
                        firebase.database().ref().update(updates).then(function () {
                            commit('SET_LOADING', false)
                            resolve()
                        })
                    }).catch(function (err) {
                        commit('SET_LOADING', false)
                        reject(err)
                    })
                }).catch(function (err) {
                    commit('SET_LOADING', false)
                    reject(err)
                })
            })
        },
        async loadWithdraw ({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                let path = `admin/withdraw`
                let loadCommit ='SET_WITHDRAW_LIST'
                firebase.database().ref(path).orderByChild('status').equalTo('pending').once('value',
                    function (snapshot) {
                        let items =  []
                        if (snapshot.val()) {
                            snapshot.forEach(function (child) {
                                items.push({
                                    ...child.val(),
                                    id: child.key
                                });
                            });
                        }
                        commit(loadCommit, items)
                        resolve()
                    }, function (error) {
                        console.error(error)
                        reject()
                    },
                )
            })
        },
        async approveWithdraw ({ commit, state, rootState }, payload) {
            return new Promise((resolve, reject) => {
                // console.log(payload.type)
                commit('SET_LOADING', true)
                if (payload.type === 'My bank account') {
                    axios.post(vars.urlProd+'/transferAccount', {id: payload.id}).then(res => {
                        commit('SET_LOADING', false)
                        resolve()
                    }).catch(error => {
                        commit('SET_LOADING', false)
                        reject(error.response)
                    })
                } else {
                    let newStatus = {
                        status: 'approved'
                    }
                    firebase.database().ref(`admin/withdraw/${payload.id}`).update(newStatus).then(function () {
                        commit('SET_LOADING', false)
                        resolve()
                    }).catch(function (err) {
                        commit('SET_LOADING', false)
                        reject(err)
                    })
                }
            })
        },

    },
    getters: {
        getUploadsPending (state) {
            return state.uploads_pending
        },
        getWithdraw (state) {
            return state.withdraw_list
        },
        getLoading (state) {
            return state.isLoading
        },
    },

}
